import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import emailjs from 'emailjs-com';

import spl from './splash.module.css'

function Splash({ history }) {
    useEffect(() => { history.push('/') }, [history])
    const [error, setError] = useState();
    const [success, setSuccess] = useState();

    function sendEmail(e) {
        e.preventDefault();

        emailjs.sendForm('gts-gmail', 'template_F4bjrfVw', e.target, 'user_7kw7PU52rbkPFIjK7jJnE')
            .then(() => onEmail(true))
            .catch(() => onEmail(false));
    }

    const onEmail = success => { setSuccess(success); setError(!success) }

    const renderThankyou = <p className={spl.succ}>Thank you for your interest!<br/>We will be in touch shortly.</p>
    const renderError = <p className={spl.err}>Oops something went wrong! Please try again!</p>
    
    const fields = [
        {name: "Events", icon: "glass-cheers"},
        {name: "Concierge", icon: "concierge-bell"},
        {name: "Experiences", icon: "stars"},
        {name: "Travel Design", icon: "passport"} 
    ]

    const renderFields = () => fields.map((f, i) => <span key={i} className={spl.field}><FontAwesomeIcon icon={['fal', f.icon]} />{f.name}</span>)

    return (
        <article className={spl.splash}>
            <div className={spl.splashLeft}>
                <div className={spl.container}>
                    <h1 className={spl.logo}><cap>F</cap>ee<cap>T</cap>hings</h1>
                    <h3 className={spl.logoTag}>Nantucket</h3>

                    <section className={spl.taglineLead}>
                        Experiental Concierge with a sense of place
                    </section >
                </div>

            </div>

            <div className={spl.splashRight}>
                <div className={spl.container}>
                    <p className={spl.teaser}>Coming Soon</p>
                    
                    <section className={spl.fields}>
                        { renderFields() }
                    </section>


                    <section>
                        {
                            success ?
                            renderThankyou :
                            <form className={spl.form} onSubmit={sendEmail}>
                                <input type="email" name="to_email" placeholder="Your email address"/>
                                <input type="hidden" name="referrer_location" value="feethingsnantucket.com" />
                                <input type="submit" value="Inquire"/>
                            </form>
                        }
                        {error && renderError}           
                    </section>

                    <section className={spl.tagline}>
                        <p>Local name, global reach</p>
                        <p>Experience Nantucket with new perspective</p>
                    </section>

                    <section className={spl.socials}>
                        <a href="#"><FontAwesomeIcon icon={['fab', 'instagram']} /></a>
                        <a href="#"><FontAwesomeIcon icon={['fab', 'facebook-f']} /></a>
                        <a href="https://www.pinterest.co.uk/feethings/" target="_blank" rel="no_referrer"><FontAwesomeIcon icon={['fab', 'pinterest-p']} /></a>
                    </section>
                </div>
            </div>
        </article>
    )
}

export default withRouter(Splash);
