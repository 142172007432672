import React from 'react'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import hdr from './header.module.css'

export default function Header({ pageName }) {
    return (
        <header className={hdr.header}>


        </header>
    )
}